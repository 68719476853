.invertColors {
  --bs-tooltip-color: var(--bs-primary) !important;
  --bs-tooltip-bg: var(--bs-white) !important;

  a {
    color: var(--bs-primary) !important;
  }
}

.unselectable {
  pointer-events: none;
}
