@font-face {
  font-family: 'icomoon';
  src: url('/public/fonts/icomoon.eot?yub3y2');
  src:
    url('/public/fonts/icomoon.eot?yub3y2#iefix') format('embedded-opentype'),
    url('/public/fonts/icomoon.ttf?yub3y2') format('truetype'),
    url('/public/fonts/icomoon.woff?yub3y2') format('woff'),
    url('/public/fonts/icomoon.svg?yub3y2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gift:before {
  content: '\e900';
  color: #fff;
}
.icon-calendar:before {
  content: '\e622';
}
.icon-locker:before {
  content: '\e63e';
}
.icon-note7:before {
  content: '\e64b';
}
.icon-info:before {
  content: '\e68b';
}
.icon-pin:before {
  content: '\e65c';
}
