.helperBar {
}

@media (min-width: 992px) {
  .helperBar {
    input {
      width: 200px !important;
    }
  }
}
