.loader {
  font-size: 7px;
  text-indent: -9999em;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background: #545454;
  background: -moz-linear-gradient(left, #545454 10%, rgba(84, 84, 84, 0) 42%);
  background: -webkit-linear-gradient(left, #545454 10%, rgba(84, 84, 84, 0) 42%);
  background: -o-linear-gradient(left, #545454 10%, rgba(84, 84, 84, 0) 42%);
  background: -ms-linear-gradient(left, #545454 10%, rgba(84, 84, 84, 0) 42%);
  background: linear-gradient(to right, #545454 10%, rgba(84, 84, 84, 0) 42%);
  position: fixed;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  left: calc(50% - 5em);
  top: calc(50% - 5em);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #545454;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
