.content {
  height: auto;
  min-height: auto;
}

button.veryUnlikely {
  background-color: #f23938;
}
button.unlikely {
  background-color: #f5c54f;
}
button.likely {
  background-color: #38d02f;
}
button.veryLikely {
  background-color: #c7983f;
}

div.buttons button {
  border: 0;
  color: white;
  width: 36px;
  height: 36px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 6px;
  padding: 0;
  text-align: center;
}
