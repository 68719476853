:root {
  --zIndex-main: 100;
  --zIndex-main-monthly: 200;

  --zIndex-header: 200;
  --zIndex-header-monthly: 100;
  --zIndex-header-monthly-hover: 300;

  --zIndex-roster-stickyHeaderGrid: 30;
  --zIndex-roster-stickyHeader: 60;

  --zIndex-sidebar: 400;

  --zIndex-footer: 500;

  --zIndex-globalLoader: 9999;
}
