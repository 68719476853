@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sessionTitle {
  z-index: calc(var(--zIndex-header) + 1);
  top: 0;

  @include media-breakpoint-down(md) {
    top: -60px;
    left: 10px;
  }
}
